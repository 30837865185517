import React, { forwardRef } from 'react'
import { Input } from 'antd'

import './input-base.scss'

const { TextArea } = Input

interface IInputBaseProps {
  value?: string | number
  defaultValue?: string
  placeholder?: string
  error?: boolean | string
  inputType?: string
  autoComplete?: string
  className?: string
  disabled?: boolean
  hidden?: boolean
  allowClear?: boolean
  checked?: boolean
  isTextArea?: boolean
  min?: number
  max?: number
  rows?: number
  style?: React.CSSProperties
  id?: string
  maxLength?: number
  accept?: string
  onChange?: (e: any) => void
  onFocus?: (e: any) => void
  onBlur?: (e: any) => void
  onKeyDown?: (e: any) => void
}

export const InputBase = forwardRef<any, IInputBaseProps>((props, ref) => {
  const {
    value,
    defaultValue,
    placeholder,
    error,
    inputType = 'text',
    autoComplete,
    className = '',
    disabled,
    hidden,
    allowClear,
    isTextArea = false,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    style,
    ...rest
  } = props

  const InputStyled = !isTextArea ? Input : TextArea

  return (
    <InputStyled
      ref={ref}
      className={`input-base ${className} ${error ? 'input-error' : ''}`}
      autoComplete={autoComplete}
      type={inputType}
      value={value ?? defaultValue}
      disabled={disabled}
      hidden={hidden}
      allowClear={allowClear}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      style={style}
      {...rest}
    />
  )
})

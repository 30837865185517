import { DISCOUNT_TYPE } from './promo.dto'
import { BOOKING_TYPE } from './booking.dto'

export enum DiscountedPriceOffServicesEnum {
  YES = 'yes',
  NO = 'no'
}

export enum EachServiceDifferentDiscountTierEnum {
  YES = 'yes',
  NO = 'no'
}

export interface MembershipTierDto {
  id: string
  membershipTier: string
  requiredAnnualSpend: number
  discountedPriceOffServices: DiscountedPriceOffServicesEnum
  eachServiceDifferentDiscountTier: EachServiceDifferentDiscountTierEnum
  membershipTierIcon: string
  membershipTierIconUrl: string
  tierBenefit: TierBenefitDto
  isDefault: boolean
}

export interface TierBenefitDto {
  pointEarned: number
  moneySpent: number
  pointUsed: number
  moneyDiscount: number
  bookingBenefits?: BookingBenefitDto[]
  customizedBenefits?: string[]
}

export interface BookingBenefitDto {
  discountType: DISCOUNT_TYPE
  discountValue: number
  bookingType: BOOKING_TYPE
}

export interface ListMembershipResponseDto {
  items: MembershipTierDto[]
  count: number
}

export interface NewMembershipRequestBodyDto {
  membershipTier: string
  requiredAnnualSpend: number
  discountedPriceOffServices: DiscountedPriceOffServicesEnum
  eachServiceDifferentDiscountTier: EachServiceDifferentDiscountTierEnum
  membershipTierIcon: string
  tierBenefit: TierBenefitDto
  iconIsTemporary?: boolean
}

export interface FilterMembershipTierQueryDto {
  offset?: number
  limit?: number
  search?: string
  membershipTierId?: string
  isActive?: boolean
}

export interface UploadUrlDto {
  fileId: string
  preSignUrl: string
  loadUrl: string
  loadTempUrl?: string
}

export enum RenewMembershipType {
  CUSTOM_DATE = 'CUSTOM_DATE',
  FIXED_PERIOD = 'FIXED_PERIOD'
}

export enum RenewFromType {
  FROM_FIRST_DAY_OF_CALENDAR_YEAR = 'FROM_FIRST_DAY_OF_CALENDAR_YEAR',
  FROM_DATE_OF_ACCOUNT_ACTIVATION = 'FROM_DATE_OF_ACCOUNT_ACTIVATION'
}

export interface RenewalExplanationItem {
  title?: string
  description?: string
}

export interface MembershipTermsAndRenewalDto {
  renewType: RenewMembershipType
  month?: number
  dateMonth?: string
  renewFromType?: RenewFromType
  explanations?: RenewalExplanationItem[]
}

export interface MembershipPointSystemDto {
  completedProfileReceivedPoint: number
  reviewReceivedPoint: number
  registerReceivedPoint: number
  refererReceivedPoint: number
  refereeReceivedPoint: number
  expirePointMonth: number
}
